import { Component, DestroyRef, OnInit, ViewChild } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import {
  DsSnackbarService,
  DsSnackbarType,
} from '@design-system/feature/snackbar';
import { from, timer } from 'rxjs';
import { filter } from 'rxjs/operators';
import {
  SettingsActions,
  SettingsSelectors,
  selectSettings,
} from './shared/store/settings';

import { Location } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { SwUpdate } from '@angular/service-worker';
import { UserService } from '@features/auth';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ApplicationInsightsService } from '@shared-lib/app-insights';
import { filterTruthy } from '@shared-lib/rxjs';
import { environment } from '../environments/environment';
import { InstallPromptComponent } from './install-prompt/install-prompt.component';
import { NewVersionInfoComponent } from './new-version-info/new-version-info.component';
import { NoEquipmentInfoComponent } from './no-equipment-info/no-equipment-info.component';
import { NAVBARITEMS } from './shared/data/navbar-items';
import { NavbarItem } from './shared/model/navbar-item.model';
import { OperatorMonitorState } from './shared/store';
import { AlertsActions } from './shared/store/alerts';
import { HeaderSelectors } from './shared/store/header';
import { MaintenanceActions } from './shared/store/maintenance/maintenance.actions';
import { MobileSetupService } from './shared/services/mobile-setup.service';
import { Capacitor } from '@capacitor/core';
import { MatSidenav } from '@angular/material/sidenav';
import { StatusBar, Style } from '@capacitor/status-bar';
import { dsConfig } from '@design-system/cdk/config';
import { FcmService } from './shared/services/fcm.service';
import { SignalRService } from '@connected/data-access/fleet-service/signal-r';
import {
  ConnectedActions,
  FeatureFlagActions,
  FeatureFlagFeature,
} from '@connected/shared/store';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { AppTrackingTransparency } from 'capacitor-plugin-app-tracking-transparency';
import { CookieConsentService } from '@paldesk/cookie-consent';
import { ProductTypes } from '@config';
import { GoogleAnalytics4Service } from '@shared-lib/google-analytics';

@Component({
  selector: 'opm-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: false,
})
export class AppComponent implements OnInit {
  @ViewChild(MatSidenav) sidenav;
  isOffline = false;
  trackingIsStarted = false;
  navItems: NavbarItem[] = NAVBARITEMS;

  hasPpgCommunicationActive: boolean;

  readonly minHeight: number = 575;

  isNative = Capacitor.isNativePlatform();
  isIos = Capacitor.getPlatform() === 'ios';
  hasTrackingInitialized = false;

  wrapperSetup = this.isNative
    ? { hasAppLauncher: false, hasFeedback: false, hasSearch: false }
    : { hasAppLauncher: true, hasFeedback: true, hasSearch: true };

  constructor(
    private swUpdate: SwUpdate,
    private dialog: MatDialog,
    private store: Store<OperatorMonitorState>,
    public router: Router,
    public userService: UserService,
    private _appInsights: ApplicationInsightsService,
    private _snackbar: DsSnackbarService,
    private _translateService: TranslateService,
    private _mobileSetup: MobileSetupService,
    public location: Location,
    private _fcmService: FcmService,
    private _signalRService: SignalRService,
    private _cookieService: CookieConsentService,
    private readonly _googleAnalytics: GoogleAnalytics4Service,
    private readonly _destroyRef: DestroyRef,
  ) {
    this._fcmService.initPush();
    this.store.dispatch(
      ConnectedActions.SetIsOperatorMonitor({ isOperatorMonitor: true }),
    );

    if (this.isNative) {
      this._mobileSetup.setUpMobileApp();
      StatusBar.setStyle({ style: Style.Dark });
      if (!this.isIos)
        StatusBar.setBackgroundColor({ color: dsConfig.colors.primary['600'] });
      this._iosTrackingSetup();
    }

    router.events.pipe(takeUntilDestroyed()).subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.sidenav.close();
      }
    });

    this.userService.currentUser
      .pipe(filterTruthy(), takeUntilDestroyed())
      .subscribe((user) => {
        this.prepareMaintenanceChecklist();
        this.store.dispatch(AlertsActions.SetLanguage({ payload: user.lang }));
        this.store.dispatch(FeatureFlagActions.loadFeatureFlags());
      });

    this.store
      .select(selectSettings)
      .pipe(takeUntilDestroyed())
      .subscribe((settingsState) => {
        localStorage.setItem('settings_state', JSON.stringify(settingsState));
      });
    this.store
      .select(HeaderSelectors.getSelectedEquipment)
      .pipe(takeUntilDestroyed())
      .subscribe(
        (equipment) =>
          (this.hasPpgCommunicationActive =
            equipment?.equipment_type_icon === ProductTypes.AccessPlatforms &&
            equipment.has_telematics),
      );
    if (!this.isNative && environment.production) this.setUpServiceWorker();

    this.store
      .select(FeatureFlagFeature.selectIsSignalREnabled)
      .pipe(filterTruthy(), takeUntilDestroyed())
      .subscribe((flag) => {
        if (flag) {
          this._signalRService.configureConnection(
            this.userService.accessToken,
          );
        }
      });

    toObservable(this._googleAnalytics.$isInitialized)
      .pipe(
        filter((isInitialized) => isInitialized),
        takeUntilDestroyed(),
      )
      .subscribe(() => {
        this._determinePlatformUsage();
      });
  }
  ngOnInit() {
    if (
      this.isNative &&
      (window.outerHeight < this.minHeight ||
        window.outerWidth < this.minHeight)
    ) {
      (screen.orientation as any).lock('portrait');
    }
    this.startOnlineCheck();
  }

  showOfflineInfo() {
    this._snackbar.queue(
      this._translateService.instant('offline_banner.text'),
      {
        type: DsSnackbarType.Error,
      },
    );
  }

  showOnlineInfo() {
    this._snackbar.queue(this._translateService.instant('online_banner.text'), {
      type: DsSnackbarType.Success,
    });
  }

  showNoEquipmentInfo() {
    this.dialog.open(NoEquipmentInfoComponent, {
      width: '500px',
    });
  }

  private _determinePlatformUsage() {
    let value = 'web';
    if (Capacitor.isNativePlatform()) value = Capacitor.getPlatform();
    if (navigator['standalone']) value = 'PWA';
    if (value === 'web') value = navigator.platform || 'web';

    this._googleAnalytics.event('platform_usage', { platform_usage: value });
  }

  private setUpServiceWorker() {
    if ('serviceWorker' in navigator && environment.production) {
      navigator.serviceWorker.register('ngsw-worker.js');
    }

    if (this.swUpdate.isEnabled) {
      this.swUpdate.versionUpdates
        .pipe(
          takeUntilDestroyed(this._destroyRef),
          filter((evt) => evt.type === 'VERSION_READY'),
        )
        .subscribe(() => {
          const dialogRef = this.dialog.open(NewVersionInfoComponent, {
            width: '500px',
          });
          dialogRef
            .afterClosed()
            .pipe(takeUntilDestroyed(this._destroyRef))
            .subscribe((updateConfirmed) => {
              if (updateConfirmed) {
                window.location.reload();
              }
            });
        });
    }

    if (
      !navigator['standalone'] &&
      ['iPhone', 'iPad', 'iPod'].includes(navigator.platform)
    ) {
      this.store
        .select(SettingsSelectors.getInstallPromptLastShown)
        .pipe(
          filter(
            (r) => this.isValidPrompDate(r),
            takeUntilDestroyed(this._destroyRef),
          ),
        )
        .subscribe(() => this.openInstallPrompt());
    }
  }

  private isValidPrompDate(lastPromptDate?: Date) {
    if (lastPromptDate) {
      lastPromptDate.setDate(lastPromptDate.getDate() + 14);
      if (lastPromptDate < new Date()) {
        return true;
      }
      return false;
    }
    return true;
  }

  private openInstallPrompt() {
    this.dialog.open(InstallPromptComponent, {
      width: '252px',
    });
    this.store.dispatch(
      SettingsActions.SetInstallPromptDate({ payload: new Date() }),
    );
  }

  // check for online every second
  private startOnlineCheck() {
    timer(0, 1000)
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe(() => {
        if (!navigator.onLine) {
          if (!this.isOffline) {
            this.showOfflineInfo();
          }
          this.isOffline = true;
        } else {
          if (this.isOffline) {
            this.showOnlineInfo();
          }
          this.isOffline = false;
        }
      });
  }

  private _iosTrackingSetup() {
    from(AppTrackingTransparency.getStatus())
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe((status) => {
        if (status.status === 'notDetermined' || status.status === 'restricted')
          from(AppTrackingTransparency.requestPermission())
            .pipe(takeUntilDestroyed(this._destroyRef))
            .subscribe((permission) => {
              if (
                permission.status === 'authorized' &&
                !this.hasTrackingInitialized
              ) {
                this._startTracking();
                this._cookieService.setConsent(true);
                this.hasTrackingInitialized = true;
              }
            });
        else if (
          status.status === 'authorized' &&
          !this.hasTrackingInitialized
        ) {
          this._startTracking();
          this._cookieService.setConsent(true);
          this.hasTrackingInitialized = true;
        }
      });
  }

  private _startTracking() {
    this._appInsights.startTracking('FE: Operator Monitor');
  }

  private prepareMaintenanceChecklist() {
    const prepareMaintenanceChecklistCalled = sessionStorage.getItem(
      'prepareMaintenanceChecklistCalled',
    );
    if (!prepareMaintenanceChecklistCalled) {
      this.store.dispatch(MaintenanceActions.PrepareMaintenanceChecklist());
    }
  }
}
