import { EffectsModule } from '@ngrx/effects';
import { ActionReducerMap } from '@ngrx/store';
import {
  DashboardEffects,
  dashboardReducer,
  DashboardState,
} from './dashboard';
import { HeaderEffects, headerReducer, HeaderState } from './header';
import {
  JobPlannerEffects,
  jobPlannerReducer,
  JobPlannerState,
} from './job-planner';
import { MclEffects, mclReducer, MclState } from './maintenance';
import { settingsReducer, SettingsState } from './settings';
import {
  CommunicationState,
  communicationReducer,
  CommunicationEffects,
} from './communication';
import { AlertsState, alertsReducer } from './alerts';
import { AlertsEffects } from './alerts/alerts.effects';
import {
  ConnectedFeature,
  ConnectedState,
  FeatureFlagEffects,
  FeatureFlagFeature,
  FeatureFlagState,
} from '@connected/shared/store';
import {
  StatusCodesEffects,
  StatusCodesFeature,
  StatusCodesState,
} from '@connected/status-code/util';
import {
  tableSettingsKey,
  tableSettingsReducer,
  TableSettingsState,
} from '@design-system/components/table-settings';
import {
  MaintenanceEffects,
  MaintenanceFeature,
  MaintenanceState,
} from '@connected/maintenance/util';
import {
  DocumentsEffects,
  DocumentsFeature,
  DocumentsState,
} from '@connected/document/util';

export interface OperatorMonitorState {
  dashboard: DashboardState;
  header: HeaderState;
  jobPlanner: JobPlannerState;
  settings: SettingsState;
  alerts: AlertsState;
  mcl: MclState;
  communication: CommunicationState;
  [FeatureFlagFeature.name]: FeatureFlagState;
  [ConnectedFeature.name]: ConnectedState;
  [StatusCodesFeature.name]: StatusCodesState;
  [tableSettingsKey]: TableSettingsState;
  [MaintenanceFeature.name]: MaintenanceState;
  [DocumentsFeature.name]: DocumentsState;
}

export const operatorMonitorReducers: ActionReducerMap<
  OperatorMonitorState,
  any
> = {
  header: headerReducer,
  jobPlanner: jobPlannerReducer,
  dashboard: dashboardReducer,
  settings: settingsReducer,
  alerts: alertsReducer,
  mcl: mclReducer,
  communication: communicationReducer,
  [FeatureFlagFeature.name]: FeatureFlagFeature.reducer,
  [ConnectedFeature.name]: ConnectedFeature.reducer,
  [StatusCodesFeature.name]: StatusCodesFeature.reducer,
  [MaintenanceFeature.name]: MaintenanceFeature.reducer,
  [tableSettingsKey]: tableSettingsReducer,
  [DocumentsFeature.name]: DocumentsFeature.reducer,
};

export const OperatorMonitorEffects = [
  EffectsModule.forFeature([
    DashboardEffects,
    JobPlannerEffects,
    HeaderEffects,
    AlertsEffects,
    MclEffects,
    CommunicationEffects,
    FeatureFlagEffects,
    StatusCodesEffects,
    MaintenanceEffects,
    DocumentsEffects,
  ]),
];
