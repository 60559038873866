import { Location } from '@angular/common';
import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { AlertService } from '@connected/data-access/fleet-service';
import { PalcodeService } from '@paldesk/shared-lib/data-access/palcode-service-generated';
import { Observable } from 'rxjs';
import {
  catchError,
  filter,
  map,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';
import { OperatorMonitorState } from '..';
import { MapAlertCodePipe } from '../../../alerts/alerts-finder/alert-to-code-pipe/alert-to-code-pipe.pipe';
import { AlertsActions } from './alerts.actions';
import { AlertsSelectors } from './alerts.selectors';
import { HeaderActions } from '../header';
import { SignalRService } from '@connected/data-access/fleet-service/signal-r';
import { FeatureFlagFeature } from '@connected/shared/store';

@Injectable()
export class AlertsEffects {
  private alertToCodePipe = new MapAlertCodePipe();
  private actions$: Actions = inject(Actions);
  private store: Store<OperatorMonitorState> = inject(Store);

  loadDetails$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AlertsActions.LoadDetails),
      switchMap(({ payload }) =>
        this.alertService.getAlertsPerEquipmentNumber(payload).pipe(
          map((data) =>
            AlertsActions.LoadDetailsSuccess({
              alerts: data.alerts,
            }),
          ),
          catchError(() => [AlertsActions.LoadDetailsError()]),
        ),
      ),
    ),
  );

  SetLanguage$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AlertsActions.SetLanguage),
      filter(() => this.location.path().includes('/codes/finder')),
      switchMap(() => [
        AlertsActions.SearchCodes(),
        AlertsActions.LoadProducts(),
        AlertsActions.LoadHardwares(),
      ]),
    ),
  );

  searchCodes$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AlertsActions.SearchCodes, AlertsActions.SetSearchInput),
      withLatestFrom(
        this.store.select(AlertsSelectors.getSearchInput),
        this.store.select(AlertsSelectors.getSelectedLanguage),
      ),
      filter(
        ([, searchInput]) =>
          !!searchInput?.code.length && !!searchInput?.apiKey,
      ),
      switchMap(([, searchInput, language]) =>
        this.palCodeService
          .getPublicCodes(
            searchInput?.apiKey,
            searchInput?.code,
            undefined,
            undefined,
            language,
          )
          .pipe(
            map((data) =>
              AlertsActions.SearchCodesSuccess({
                payload: data.data?.map((d) => ({
                  ...d,
                  error_type: this.alertToCodePipe.transform(d)?.alert_type,
                })),
              }),
            ),
            catchError(() => [AlertsActions.SearchCodesError()]),
          ),
      ),
    ),
  );

  loadProducts$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AlertsActions.LoadProducts),
      withLatestFrom(this.store.select(AlertsSelectors.getSelectedLanguage)),
      switchMap(([, language]) =>
        this.palCodeService.getProducts(language).pipe(
          map((response) =>
            AlertsActions.LoadProductsSuccess({ payload: response }),
          ),
          catchError(() => [AlertsActions.LoadProductsError()]),
        ),
      ),
    ),
  );

  loadHardwares$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AlertsActions.LoadHardwares),
      withLatestFrom(this.store.select(AlertsSelectors.getSelectedLanguage)),
      switchMap(([, language]) =>
        this.palCodeService.getHardware(language).pipe(
          map((response) =>
            AlertsActions.LoadHardwaresSuccess({ payload: response }),
          ),
          catchError(() => [AlertsActions.LoadHardwaresError()]),
        ),
      ),
    ),
  );

  loadPublicCodeByID$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AlertsActions.LoadPublicCodeByID),
      withLatestFrom(this.store.select(AlertsSelectors.getSelectedLanguage)),
      switchMap(([codeInput, language]) =>
        this.palCodeService
          .getPublicCodeById(
            codeInput.payload.apiKey,
            +codeInput.payload.code,
            language,
          )
          .pipe(
            map((response) =>
              AlertsActions.LoadPublicCodeByIDSuccess({ payload: response }),
            ),
            catchError(() => [AlertsActions.LoadPublicCodeByIDError()]),
          ),
      ),
    ),
  );

  loadCodeByID$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AlertsActions.LoadCodeByID),
      withLatestFrom(this.store.select(AlertsSelectors.getSelectedLanguage)),
      switchMap(([code, language]) =>
        this.palCodeService.getCodeById(+code.payload, language).pipe(
          map((response) =>
            AlertsActions.LoadCodeByIDSuccess({ payload: response }),
          ),
          catchError(() => [AlertsActions.LoadCodeByIDError()]),
        ),
      ),
    ),
  );

  alertsUpdate$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(HeaderActions.SelectEquipment),
      withLatestFrom(
        this.store.select(FeatureFlagFeature.selectIsSignalREnabled),
      ),
      filter(([, isEnabled]) => isEnabled),
      switchMap(([{ payload }]) =>
        this._signalRService.alertsListener().pipe(
          map(() =>
            AlertsActions.LoadDetails({
              payload: payload.equipment_number || '',
            }),
          ),
        ),
      ),
    ),
  );

  constructor(
    private alertService: AlertService,
    private palCodeService: PalcodeService,
    public location: Location,
    private _signalRService: SignalRService,
  ) {}
}
